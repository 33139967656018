import React from 'react'
import styled from 'styled-components'

const Card: React.FC = ({ children }) => <StyledCard>{children}</StyledCard>

const StyledCard = styled.div`
  background: #1a1a1a;
  /* border: 2px solid #666666; */
  border-radius: 12px;
  /* box-shadow: 1px 1px 0px ${(props) => props.theme.color.grey[100]}; */
  display: flex;
  flex: 1;
  flex-direction: column;
  color: #666666;
`

export default Card
