import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

import Container from '../Container'
import Logo from '../Logo'

import AccountButton from './components/AccountButton'
import Nav from './components/Nav'

interface TopBarProps {
  onPresentMobileMenu: () => void
}

const TopBar: React.FC<TopBarProps> = ({ onPresentMobileMenu }) => {
  return (
    <StyledTopBar>
      <Container size="lg">
        <StyledTopBarInner>
          <StyledLogoWrapper>
            <Logo />
          </StyledLogoWrapper>
          <SPAN>
            <StyledAccountButtonWrapper>
              <AccountButton />
            </StyledAccountButtonWrapper>
    
            {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-start' }}>
              <Nav />
            </div> */}
          </SPAN>
        </StyledTopBarInner>

        {/* <StyledNavMobile>
          <StyledLinkMobile exact activeClassName="active" to="/">
            TRANSFER MONEY
          </StyledLinkMobile>

          <StyledLinkMobile exact activeClassName="active" to="/stake">
            STAKING
          </StyledLinkMobile>
       
          <StyledLinkMobile exact activeClassName="active" to="/farms">
            FARMING
          </StyledLinkMobile>
      
          <StyledLinkMobile exact activeClassName="active" to="/" style={{color: '#b8e2fc'}}>
            REMIT EXCHANGE
          </StyledLinkMobile>
  
          <StyledLinkMobile exact activeClassName="active" to="/about" >
            ABOUT
          </StyledLinkMobile>
        </StyledNavMobile> */}


      </Container>
    </StyledTopBar>
  )
}

const StyledLogoWrapper = styled.div`
  width: 260px;
  
  @media (max-width: 400px) {
    width: auto;
    display: flex;
    justify-content: center;
  }
`

const StyledTopBar = styled.div`
  /* background: #666666; */
`

const StyledTopBarInner = styled.div`
  align-items: center;
  display: flex;
  height: ${(props) => props.theme.topBarSize}px;
  justify-content: space-between;
  max-width: ${(props) => props.theme.siteWidth}px;
  width: 100%;

  @media (max-width: 400px) {
    display: inline-block;
    max-width: 350px;
  }
`
const StyledNavWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  @media (max-width: 400px) {
    display: none;
  }
`

const StyledAccountButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  /* width: 195px; */
  min-width: 235px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
    width: auto;
    min-width: 235px;
    margin-top: 25px;
    margin-left: 15px;
  }
`

// const StyledMenuButton = styled.button`
//   background: none;
//   border: 0;
//   margin: 0;
//   outline: 0;
//   padding: 0;
//   display: none;
//   @media (max-width: 400px) {
//     align-items: center;
//     display: flex;
//     height: 44px;
//     justify-content: center;
//     width: 44px;
//   }
// `

const StyledNavMobile = styled.nav`
  align-items: center;
  display: none;
  flex-wrap: wrap;
  margin-top: 30px;
  text-align: center;

  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }

`

const StyledLinkMobile = styled(NavLink)`
  color: ${(props) => props.theme.color.grey[400]};
  font-weight: 600;
  padding-left: ${(props) => props.theme.spacing[3]}px;
  padding-right: ${(props) => props.theme.spacing[3]}px;
  text-decoration: none;
  font-family: 'Poppins', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 12px;

  &:hover {
    color: #f8f9fa;
  }
  &.active {
    color: #fff ;
  }
  @media (max-width: 400px) {
    padding-left: ${(props) => props.theme.spacing[2]}px;
    padding-right: ${(props) => props.theme.spacing[2]}px;
  }
`

const SPAN = styled.span`
  display: flex;
  width: 870px;
  justify-content: end;
  margin-top: 60px;

  @media (max-width: 600px) {
    margin: 0 auto;
    width: 270px;
    display: flex;
    justify-content: center;
    margin-top: 0px;
    margin-bottom: 60px;
  }
  
`

// const Menu = styled.div`
//   margin-top: 10px;
//   display: none;

//   img{
//     cursor: pointer;
//   }

//   @media (max-width: 600px) {
//     display: block;
//   }

// `

export default TopBar
