import React, { useCallback } from 'react'
import styled from 'styled-components'
import { useWallet } from 'use-wallet'
import useModal from '../../../hooks/useModal'
import Button from '../../Button'
import WalletProviderModal from '../../WalletProviderModal'
import AccountModal from './AccountModal'

interface AccountButtonProps {}

const AccountButton: React.FC<AccountButtonProps> = (props) => {
  const [onPresentAccountModal] = useModal(<AccountModal />)
  const [onPresentWalletProviderModal] = useModal(
    <WalletProviderModal />,
    'provider',
  )

  const { account } = useWallet()

  const handleUnlockClick = useCallback(() => {
    onPresentWalletProviderModal()
  }, [onPresentWalletProviderModal])

  return (
    <StyledAccountButton>
      {!account ? (
        <ButtonCustom onClick={handleUnlockClick}>
          Connect to a wallet
        </ButtonCustom>
      ) : (
        <ButtonCustom onClick={onPresentAccountModal}>
          My Wallet
        </ButtonCustom>
      )}
    </StyledAccountButton>
  )
}

const StyledAccountButton = styled.div`

  @media (max-width: 400px) {
    display: flex;
    justify-content: center;
  }
`

const ButtonCustom = styled.div`
  background-image: linear-gradient(#0048bf, #00fedf);
  border-radius: 13px;
  transition: all .35s;
  padding: 8px 20px;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  margin-right: 30px;

  &:hover{
    background-color: #ec782b;
    transition: all .35s;
  }

  @media (max-width: 400px) {
    margin-right: 0px;
  }
`


export default AccountButton
