export const white = '#FFF'
export const black = '#000'

export const green = {
  500: '##00d1810',
}

export const red = {
  100: '#FFFDFE',
  200: '#fdf5c9',
  500: '#d16c00',
}

export const grey = {
  100: '#f7f4f2',
  200: '#fdf5c9',
  300: '#e2d6cf',
  400: '#fff',
  500: '#805e49',
  600: '#805e49',
  800: '#2d1e12',
  900: '#666666',
  1000: '#59ace4'
}
