import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import { useWallet } from 'use-wallet'
import imageRight from '../../assets/img/illustration_2.png'
import styled from 'styled-components'
import Button from '../../components/Button'
import Page from '../../components/Page'
import WalletProviderModal from '../../components/WalletProviderModal'
import useModal from '../../hooks/useModal'
import FarmCards from './components/FarmCards'
import Farm from '../Farm'

const Farms: React.FC = () => {
  const { path } = useRouteMatch()
  const { account } = useWallet()
  const [onPresentWalletProviderModal] = useModal(<WalletProviderModal />)
  return (
    <Switch>
      <Page>
        {/* {!!account ? ( */}
        {true ? (

          <>
            <Route exact path={path}>
             
             {/* <Container>
                <Wrapper>
                  <WrapperSpan>
                    <h3>Staking & Farming to earn REMIT & REMIT-X</h3>
                    <p>
                      Joining ULTRABOX.finance starts here where you can earn our main coins
                      REMIT & REMIT-X which will play a major role in the ULTRABOX.finance platform;
                      REMIT-X allows you to pay for special and limited art NFTs. And for all 
                      money transfers on ULTRABOX.finance the fees have to be paid in REMIT.  
                    </p>
                    <p>
                      In short: 
                    </p>
                    <p>
                      REMIT: Main token to pay for the service fees of ULTRABOX.finance, 
                      limited supply, halving function to reduce emission, governance to vote
                      on decisions for the future of ULTRABOX.finance. 
                    </p>
                    <p>
                      REMIT-X: Reward and NFT-token. More details will follow when REMIT-X is developed. 
                    </p>
                  </WrapperSpan>
                  
                  <IMAGE_1 src={imageRight} alt="image" />

                </Wrapper>
              </Container> */}

              {/* <MarginTop /> */}
            
              <FarmCards />
            </Route>

            {/* <Route path={`${path}/:farmId`}>
              <Farm />
            </Route> */}
          </>
        ) : (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flex: 1,
              justifyContent: 'center',
            }}
          >
            <Button
              onClick={onPresentWalletProviderModal}
              text="🔓 Unlock Wallet"
            />
          </div>
        )}
      </Page>
    </Switch>
  )
}

const Wrapper = styled.div`
  min-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  flex-wrap: wrap;

  @media(max-width: 800px) {
    min-width: 200px;
    flex-wrap: wrap;
  }
`

const WrapperSpan = styled.span`
  margin-top: 90px;
  width: 550px;

  @media(max-width: 800px) {
    /* color: #666666; */
    margin: 0 auto;
    width: 330px;
  }
`

const Container = styled.div`
  margin-top: 40px;

  @media(max-width: 800px) {
    /* margin-top: 60px; */
  }

  @media(min-width: 1500px) {
    margin-top: 140px;
  }

`

const MarginTop = styled.div`
  margin-top: 100px;

  @media(max-width: 800px) {
    /* margin-top: 60px; */
  }

  @media(min-width: 1500px) {
    margin-top: 110px;
  }
`

const IMAGE_1 = styled.img`
  width: 600px;
  height: 274px;

  @media(max-width: 800px) {
    margin: 0 auto;
    width: 340px;
    height: auto;
  }

  @media(min-width: 1500px) {
    width: 664px;
    height: 303px;
  }

`

export default Farms
