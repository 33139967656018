import React from 'react'
import styled from 'styled-components'
import logo from '../../assets/img/logo.png'

const Footer: React.FC = () => (
  <StyledFooter>
    <StyledFooterInner>
      <Container>
        <img src={logo} alt="logo" style={{ width: 250 }} />
      </Container>
      {/* <Container className="hide">
        <Link to="/">
          Transfer money
        </Link>
        <Link to="/">
          Staking
        </Link>
        <Link to="/" style={{ color: "#b8e2fc"}}>
         REMIT Exchange
        </Link>
        <Link to="/">
          About
        </Link>
      </Container> */}
{/* 
      <Container className="hide">
        <Link to="/">
          NFT Market
        </Link>
        <Link to="/">
          Anyswap Liquidity Pool
        </Link>
        <Link to="/">
          Coinmarketcap
        </Link>
        <Link to="/">
          Coingecko
        </Link>
      </Container> */}

      {/* <Container className="hide">
        <Link to="/">
          Community and Team
        </Link>
        <Link to="/">
          Whitepaper
        </Link>
        <Link to="/">
          Audits
        </Link>
        <Link to="/">
          Medium
        </Link>
      </Container> */}

      {/* <Container className="hide">
        <Link to="/">
          Twitter
        </Link>
        <Link to="/">
          Telegram
        </Link>
        <Link to="/">
          Discord
        </Link>
        <Link to="/">
          Facebook
        </Link>
        <Link to="/">
          Youtube
        </Link>
      </Container> */}

      {/* <ContainerMobile>
        <Link to="/">
          Twitter
        </Link>
        <Link to="/">
          Telegram
        </Link>
        <Link to="/">
          Discord
        </Link>
        <Link to="/">
          Facebook
        </Link>
        <Link to="/">
          Youtube
        </Link>
      </ContainerMobile> */}

    </StyledFooterInner>

  </StyledFooter>
)

const StyledFooter = styled.footer`
  background-color: #000000;
  display: flex;
  justify-content: center;
`

const StyledFooterInner = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px 30px;
  height: 200px;
  max-width: ${props => props.theme.siteWidth}px;
  width: 100%;

  
  @media (max-width: 600px) {
    .hide{
      display: none;
    }
    padding: 0;
    flex-direction: column;
    justify-content: center;

  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0 40px;

  a {
    color: #fff;
    text-decoration: none;
    font-size: 15px;
  }

  @media (max-width: 600px) {   
    margin: 0 auto;
  }
`

const ContainerMobile = styled.div`
  display: none;

  @media (max-width: 600px) {   
    margin: 0 auto;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    a {
      color: #fff;
      text-decoration: none;
      font-size: 13px;
      padding-right: 10px;
    }
  }
`

export default Footer