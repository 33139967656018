import React, { createContext, useEffect, useState } from 'react'

import { useWallet } from 'use-wallet'

import { Remit } from '../../remit'

export interface RemitContext {
  remit?: typeof Remit
}

export const Context = createContext<RemitContext>({
  remit: undefined,
})

declare global {
  interface Window {
    remitsauce: any
  }
}

const RemitProvider: React.FC = ({ children }) => {
  const { ethereum }: { ethereum: any } = useWallet()
  const [remit, setRemit] = useState<any>()

  // @ts-ignore
  window.remit = remit
  // @ts-ignore


  useEffect(() => {
    if (ethereum) {
      const chainId = Number(ethereum.chainId)
      const remitLib = new Remit(ethereum, chainId, false, {
        defaultAccount: ethereum.selectedAddress,
        defaultConfirmations: 1,
        autoGasMultiplier: 1.5,
        testing: false,
        defaultGas: '6000000',
        defaultGasPrice: '1000000000000',
        accounts: [],
        ethereumNodeTimeout: 10000,
      })
      setRemit(remitLib)
      window.remitsauce = remitLib
    }
  }, [ethereum])

  return <Context.Provider value={{ remit }}>{children}</Context.Provider>
}

export default RemitProvider
