import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import Card from '../Card';
import Countdown, { CountdownRenderProps } from 'react-countdown';

interface ProgressCountdownProps {
  base: Date;
  deadline: Date;
  description: string;
}

const ProgressCountdown: React.FC<ProgressCountdownProps> = ({
  base,
  deadline,
  description,
}) => {
  // const percentage =
    // Date.now() >= deadline.getTime()
    //   ? 100
    //   : ((Date.now() - base.getTime()) / (deadline.getTime() - base.getTime())) * 100;

    const data1 = new Date(base);
    const data2 = new Date(deadline);

        {/* @ts-ignore */}

    const diffEmMilissegundos = Math.abs(data2 - data1);

    // Converte a diferença para dias
    const diffEmDias = Math.ceil(diffEmMilissegundos / (1000 * 60 * 60 * 24));

  // const countdownRenderer = (countdownProps: CountdownRenderProps) => {
  //   const { days, hours, minutes, seconds } = countdownProps;
  //   const h = String(days * 24 + hours);
  //   const m = String(minutes);
  //   const s = String(seconds);
  //   return (
  //     <StyledCountdown>
  //       {h.padStart(2, '0')}:{m.padStart(2, '0')}:{s.padStart(2, '0')}
  //     </StyledCountdown>
  //   );
  // };

  const countdownRenderer = (countdownProps: CountdownRenderProps) => {
    const { days, hours, minutes, seconds } = countdownProps;
    const totalHours = days * 24 + hours;
    const h = String(totalHours);
    const m = String(minutes);
    const s = String(seconds);
    const daysRemaining = Math.ceil(totalHours / 24); // Calcular dias restantes

    return (
      <StyledCountdown>
        {/* @ts-ignore */}
        {daysRemaining} days
        {/* {daysRemaining} dias, {h.padStart(2, '0')}:{m.padStart(2, '0')}:{s.padStart(2, '0')} */}
      </StyledCountdown>
    );
  };
  return (
    <StyledCardContentInner>
      <Cont>
        <StyledDesc>{description}</StyledDesc>
        {/* @ts-ignore */}
        <Countdown date={deadline} renderer={countdownRenderer} daysInHours/>
      </Cont>
    </StyledCardContentInner>
  );
};

const Cont = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 5px;

  font-weight: 700;

`;

const StyledCountdown = styled.p`
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  margin: 0 0 6px 0;
`;

// const StyledProgressOuter = styled.div`
//   width: 100%;
//   height: 8px;
//   border-radius: 3px;
//   background: ${(props) => props.theme.color.grey[700]};
// `;

// const StyledProgress = styled.div<{ progress: number }>`
//   width: ${(props) => props.progress}%;
//   height: 100%;
//   border-radius: 3px;
//   background: ${(props) => props.theme.color.grey[100]};
// `;

const StyledDesc = styled.span`
  color: #666666;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  margin-top: 12px;
`;

const StyledCardContentInner = styled.div`
  height: 43px;
  width: 225px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 2px solid #666666;
  border-radius: 8px;

  @media(max-width: 800px) {
    margin-top: 25px;
    margin-bottom: 25px;
  }
`;

export default ProgressCountdown;

