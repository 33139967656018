
export const SUBTRACT_GAS_LIMIT = 100000

export const networkId = 11221

export const contractAddresses = {
  remit: {
    11221: '0x2981673290899cc1501142C900F1eb31d8C680Ef',
  },
  weth: {
    11221: '0x2981673290899cc1501142C900F1eb31d8C680Ef',
  },
  remitStake: {
    11221: '0x2981673290899cc1501142C900F1eb31d8C680Ef',
  },
  remitVaultFarmEth: {
    11221: '0x2981673290899cc1501142C900F1eb31d8C680Ef',
  },
  stake2: {
    11221: '0x5302E3483bA1E04c2629444a3EBf67b62b47a55F',
  },
  stake3_5: {
    11221: '0x5827281910a58a9Ac300d39F1bD6Eec164A121A3',
  },
  stake3: {
    11221: '0x52B1D443363D41837EE9FD9B6eC6050F6b4D9931',
  },
}

export const supportedPools = []

export const farmingPools = { 
  remitETH: {
    11221: '0x2981673290899cc1501142C900F1eb31d8C680Ef',
  }
}

