import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import chef from '../../assets/img/logo.png'
import R from '../../assets/img/images/logo.png'


const Logo: React.FC = () => {
  return (
    <StyledLogo to="/">
      <IMAGE src={chef}  />
    </StyledLogo>
  )
}

const LogoCenter = styled.div`
  display: none;
  align-items: center;
  justify-content: center;

  @media (max-width: 600px) {
    width: 330px;
    display: flex;
  }
`

const Container = styled.div`
  display: flex;
  margin-top: 15px;
  align-items: center;
  width: 320px;

  @media (max-width: 600px) {
    display: none;
  }
`

const StyledLogo = styled(Link)`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 0;
  /* margin-top: 25px; */
  min-height: 44px;
  min-width: 44px;
  padding: 0;
  text-decoration: none;
`

const StyledText = styled.span`
  color: #666666;
  /* font-family: 'Reem Kufi', sans-serif; */
  font-size: 12px;
  font-weight: 700;
  margin: 0 7px;
  margin-top: 2px;
  @media (max-width: 400px) {
    display: none;
  }
`

const IMAGE = styled.img`
  margin-top: 60px;
  height: 54px;

  @media (max-width: 800px) {
    /* display: none; */
    margin-top: 0px;
    /* margin-left: 20px; */
    height: 30px;

  }
`

const IMAGEMOBILE = styled.img`
  margin-top: 60px;
  height: 94px;

  @media (max-width: 800px) {
    margin-top: 0px;
    /* margin-left: 20px; */
    height: 95px;

  }
`

export default Logo
